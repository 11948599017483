body {
  background: #fff;
}
.p-grey {
  color: #757575;
  /* font-family: HelveticaNeue; */
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.sub-header {  
  /* font-family: HelveticaNeue; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.card-types{  
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.blue{
    color: #4053ae;
}

.black{
    color: #262c36;
}

.grey-italic{
    color : #757575;
    font-style: italic;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.center{
  text-align: center;
}

.left{
    text-align: left;
}

.main-header {  
  /*font-family: HelveticaNeue;*/
  font-size: 30px;
  font-weight: 700; 
  line-height: 36px;  
}
@media only screen and (max-width: 720px) {
  img.resources {
    width: 100%;
  }
}

.visting{
    background-image: url("../../images/Background_new.png");
    background-repeat: no-repeat;
    background-repeat: none;    
    background-size: cover;
    width:auto;
    height: auto;  
    margin : 0;
    padding-bottom : 60px;
}

.visting1{   
    background-image: url("../../images/Background_cta.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:auto;
    height: auto;
    margin:30px;
    padding : 60px;
}

.justify-items{
    display: flex;    
    align-items : center;
}

.query{
    padding-left : 100px;    
    flex-direction: column;
    justify-content: center;    
    text-align: center;
    flex-wrap : wrap;   
}

.button-text{
  background-color: #4053AE;
  border-radius: 0 20px;
  width : 400px;
  height: 60px;  
  border : none;  
  color: #FFFFFF;     
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  outline : none;
  cursor : pointer;
}

.column {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.sticky{
  position: -webkit-sticky;
  position:sticky;
  bottom:0;
}


/* @media only screen and (max-width:720px) {
    img {
        width:100%;
    }
} */

/* h1{
    font-size:10px;
    font-weight: 500;
    line-height: 36px;
    text-align: left; 
}
h2{
    color: #4053AE;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
h3{
    color: #262C36;
    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: 700;
    padding:20px;
    letter-spacing: -0.01px;
    line-height: 36px;
    text-align: center;
}
p{
    color: #262C36;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.p1{
    color: #757575;
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    width: 705px;
    text-align: left
}

.p2{
    color: #262C36;
    float: Right;
    text-align: center;
    color: #262C36;
    font-family: HelveticaNeue;
    font-size: 25px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: -0.01px;
}

@media only screen and (max-width:720px) {
    img {
        width:100%;
    }   
} */
