/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */

.button {
  font-size:14px;
  color:white;
  background-color:#3040C4;
  border-color:none;
  border:none;
  border-radius:2px;
  padding:12px 30px;
  font-weight: 700;
  cursor:pointer;
  text-transform: uppercase;
}
.button a{
  text-decoration: none;
  color:white;
}
.container {
  margin-left:auto;
  margin-right:auto;
  max-width: 1024px;
  clear: both;
}
.bold{
  font-weight: 700;
}
.center{
  text-align: center;
}
.secondaryColor{
  color:#979797;
}
.headingColor{
  color:#21283a;
}
